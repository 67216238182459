import React from "react";

function ConfirmationButton(props){

    return(
        <button className="btn" disabled={!props.enabled} id="btn_confirm" onClick={() => props.onClick()}>{props.enabled ? props.captionEnabled : props.captionDisabled}</button>
    )
}

export default ConfirmationButton

