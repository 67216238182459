import React, { useState,useEffect,useRef } from 'react';
import useInterval from '../Hooks/useInterval';
import JobQueue from './JobQueue';
import Player from './Player';
import Playlist from './Playlist';
import * as api from './api/api';
import * as cookies from './api/CookieManagement';
import ConfirmationButton from './confirmationButton';
import PatternInput from './PatternInput'
import CenterBox from "./centerBox";
import {PENDING, sendJobList} from "./api/api";
import UseInterval from "../Hooks/useInterval";
import {Button} from "@mui/material";




function JobForm(props) {
  const pattern = /^[a-zA-Z0-9\-_\.]+@[a-zA-Z0-9\-_\.]+\.[a-z]{2,}$/; //Pattern to validate mail address

      //const [tickerActive,setTickerActive] = useState(false);
      const [mail,setMail] = useState(cookies.getCookie("mail"));
      const [rows,setRows] = useState([]);
      const [url,setUrl] = useState("");
      const [type,setType] = useState("");
      const [sources,setSources] = useState([]);
      const [sendJoblistButtonEnabled,setSendJoblistButtonEnabled] = useState(true);
      const [useMailNotification,setUseMailNotfication] = useState(false);
      const [showPlayerState,setShowPlayerState] = useState(false);
      const [playerMinimized,setPlayerMinimized] = useState(false);
      const [selectedMediaFile,setSelectedMediaFile] = useState({});
      const [callbackAfterMediumEnds,setCallbackAfterMediumEnds] = useState(() => () => {return ;});
      const [showPlaylistState,setShowPlaylistState] = useState(false);
      const [statusCheckObject,setStatusCheckObjectState] = useState(null);
      const [selectPlaylistElement,setSelectPlaylistElement] = useState(null);
      const urlRef = React.createRef();

 const processButtonSendJobList = (mail) => {
        setSendJoblistButtonEnabled(false);
        sendJobList(
            mail,
            () => setSendJoblistButtonEnabled(true)
        )
    };

  const closePlayer = ()=>{
    setShowPlayerState(false);
    setPlayerMinimized(false);
    document.title = "YT-Downloader";

  };

  const minimizePlayer = () => {
    setPlayerMinimized(true);
  }

  const maximizePlayer = () => {
    setPlayerMinimized(false);
  }



  const closePlaylist = () => {
    setShowPlaylistState(false);
  }

  const showPlayer = (selectedMediaFileIndex,selectedMediaFile, callbackAfterMediumEnds=()=>()=>{return ;}, forceMaximized=false) => {
    //console.log(selectedMediaFile);
    //console.log(callbackAfterMediumEnds)
      //closePlayer();
    setSelectPlaylistElement(null);
    if(rows[selectedMediaFileIndex].status!==3){
        setStatusCheckObjectState({selectedMediaFileIndex: selectedMediaFileIndex,selectedMediaFile,callbackAfterMediumEnds: callbackAfterMediumEnds, forceMaximized:forceMaximized});
        return;
    }
    setStatusCheckObjectState(null);
    setCallbackAfterMediumEnds(callbackAfterMediumEnds);
    setShowPlayerState(true);
    setSelectedMediaFile(selectedMediaFile);
    if(forceMaximized)
      setPlayerMinimized(false);
    document.title = selectedMediaFile.title;

  };

  const showPlaylist = () => {
    setShowPlaylistState(true);
  }

  const statusCheck = () => {
      if(statusCheckObject===null)
          return;
      if(rows[statusCheckObject.selectedMediaFileIndex].status===3){
          setStatusCheckObjectState(null)
          showPlayer(statusCheckObject.selectedMediaFileIndex,statusCheckObject.selectedMediaFile,statusCheckObject.callbackAfterMediumEnds,statusCheckObject.forceMaximized)
      }
  }

  const removeSourceFromPlaylist = (indexToRemove) => {
        setSources(
            sources.filter(
                (v,i) => i!==indexToRemove
            )
        )
      }

  const removeChild = (id) => {
        let newRows = rows.filter(row => row.id!==id);
        setRows(newRows);
  }


  const removeClip = (id) => {
    let confirmed = window.confirm("Do you realy want remove this job?");
    if(confirmed){
        api.removeJob(id, () => {
            removeChild(id);
        });
    }
  };

    const update = (inital=false) => {
      //unsetTicker();
      let l = rows.filter(row => row.status !== 3 & row.status!==0).length;
      if(l===0 && !inital)
        return;
      api.getJobs(
          json => {
              let r = [];
              for(var k in json){
                r.push(json[k]);
              }
              setRows([]);
              setRows(r);
          }
      );
      //setTicker();
  }



useEffect(
  () => {
    update(true);
  },[]);

  useInterval(update,5000);
  UseInterval(statusCheck,500);

  const addItemToSource = (item,autoplay=false) => {
    let found = false;
    for(let i=0;i<sources.length;i++){
      if(sources[i].index===item.index && sources[i].file.type===rows[item.index].type){
        if(!autoplay) alert("The playlist already contains this item.");
        found=true;
        break;
      }
    }
    if(!found) setSources([...sources, item]);
    if(autoplay){
        setSelectPlaylistElement(item.index);
    }
  };


  const storeMailAddress = (sendMail = useMailNotification) => {
    if(sendMail){
      api.storeMailAddress(mail);
    }
    else{
      api.storeMailAddress("");
    }
  };

  const mailPatternMatched = (mail) => {
    storeMailAddress();
    cookies.setCookie("mail", mail, 28);
  };

   const addJobButtonClicked = () => {
      if(type===""){
        alert("Please select a format");
        return ;
      }

      for(var k in rows){
        if(rows[k].url === url){
          alert("This clip is aleady in your queue!");
          return ;
        }
      }
      let id = -rows.length-1;
      let newRow = {id: id,ts_start:Date.now() / 1000,ts_last_action:Date.now() / 1000,status:0, title:url, url: url,type:type};
      let r=rows.concat([newRow]);
      let url2 = url;
      setUrl("");
      setRows(r);
      api.addJob(
        url2,
        type,
        (json) => 
        { 
          //console.log(url);
          if(json.title!=null){
            storeMailAddress();
            let newRows=r;
            for(let i=0; i<newRows.length; i++){
              if(newRows[i].id===id){
                  
                  newRows[i]=json;
                  setRows([]);
                  setRows(newRows);
                  break;
              }

            }
            
          }
          else{
            removeChild(id);
            alert("Invalid URL: " + url2);
          }

        })
      };
  
      return(
          <div>
              <div onClick={showPlaylist} className="kachel bg-green"></div>
              {playerMinimized ?
                      <div onClick={maximizePlayer} className="kachel bg-blue"></div>
                   : ""
              }

    <div className={"main-container"}>
        {statusCheckObject!==null ? <CenterBox/> : ""}

            <div id="jobForm">
                      {showPlayerState ? <div style={playerMinimized ? {display: "none"} : {display: ""}}><Player
                          minimizePlayer={minimizePlayer} minimzed={playerMinimized}
                          source={selectedMediaFile} closePlayer={closePlayer}
                          callbackAfterMediumEnds={callbackAfterMediumEnds}/></div> : ""}

                      <fieldset>
                          <legend>URL</legend>
                          <input type="text" ref={urlRef} id="url" value={url} onChange={(e) => setUrl(e.target.value)}/>
                      </fieldset>
                      <fieldset>
                          <legend>Format</legend>
                          Audio
                          <input type="radio" name="type" value="audio" onChange={(e) => setType(e.target.value)}/>
                          Video
                          <input type="radio" name="type" value="video" onChange={(e) => setType(e.target.value)}/>
                      </fieldset>
                      <ConfirmationButton enabled={true} captionDisabled="In progress..." captionEnabled="Add job to queue"
                                           onClick={addJobButtonClicked}/>
                      <hr/>
                      <p>
                          <fieldset>
                              <legend>
                                  e-mail notifications
                              </legend>
                              <fieldset>
                                  <legend>
                                      e-mail address
                                  </legend>
                                  <PatternInput value={mail} placeholder="e-mail" enabled={true} pattern={pattern}
                                                onMatch={v => mailPatternMatched(v)}/>
                              </fieldset>
                              <br/>
                              <input type="checkbox" checked={useMailNotification} onClick={() => {
                                  setUseMailNotfication(!useMailNotification);
                                  storeMailAddress(!useMailNotification);
                              }}/> Send notification when finished
                              {
                                  rows.filter(v => v.status > PENDING).length > 0 ?
                                      <p>
                                          <ConfirmationButton
                                                              captionDisabled="In progress..."
                                                              captionEnabled="Send job list"
                                                              enabled={sendJoblistButtonEnabled}
                                                              onClick={v => processButtonSendJobList(mail)}/>
                                      </p> : ""
                              }

                          </fieldset>
                      </p>
                      <div>
                          <JobQueue addItem={addItemToSource} rows={rows} showPlayer={showPlayer}
                                    removeChild={removeClip}/>
                      </div>
                      {
                              <Playlist
                                        visible={showPlaylistState}
                                        removeSource={removeSourceFromPlaylist}
                                        selectElement={selectPlaylistElement}
                                        rows={rows}
                                        closePlaylist={closePlaylist}
                                        setCallbackAfterMediumEnds={setCallbackAfterMediumEnds}
                                        closePlayer={closePlayer} showPlayer={showPlayer} sources={sources}/>
                      }
                  </div>
              </div>
          </div>
      );


}

export default JobForm;