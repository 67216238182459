import React, { useEffect,useRef } from 'react';

function Player(props){

    const audioRef = useRef();
    const videoRef = useRef();
    const overlayRef = useRef();

    const closePlayer = () => {
        props.closePlayer();
    }

    const minimizePlayer = () => {
        //overlayRef.current.style="display:none";
        props.minimizePlayer();
    }

    const maximizePlayer = () => {
        overlayRef.current.style="";
    }

    useEffect(() => {
        if(props.source.type=="audio"){
            if(audioRef){
                audioRef.current.load();
                audioRef.current.play();
            }
        }
        else if(props.source.type=="video"){
            if(videoRef){
                videoRef.current.load();
                videoRef.current.play();
            }
        }
    },[props.source.stream_url]);


    // useEffect(() => {
    //     if(!props.minimized){
    //         maximizePlayer();
    //     }
    // },[props.minimized])

    const callbackAfterMediumEnds = () => {
        //console.log(props);
        props.callbackAfterMediumEnds();
    };

        return(
            <div  class="overlay">
            <div class="kasten" onClick={e => e.preventDefault()}>
            <button class="minimize-btn" onClick={minimizePlayer}>-</button> 
            <button class="close-btn" onClick={closePlayer}>×</button>
            
                <h2>{ props.source.title }</h2>
                {
                    props.source.type=="audio" ?
                    <audio ref={audioRef} autoPlay onEnded={callbackAfterMediumEnds} controls>
                        <source src={props.source.stream_url}></source>
                    </audio> :
                    <video ref={videoRef} autoPlay onEnded={callbackAfterMediumEnds}  style={{width: "auto",maxHeight: "80%",margin:"auto"}} controls>
                        <source src={props.source.stream_url}></source>
                     </video>
                }
            </div>
        </div>
        );

}

export default Player;