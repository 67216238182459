import React from 'react';

function CenterBox(){
    return (
        <div className="center-box">
            Waiting to finish process
        </div>
    )
}

export default CenterBox;