import axios, { Axios } from "axios";
import React from "react";
//const baseURL = "http://192.168.178.67:8080/api.php";
//const baseURL = "http://localhost:8080/api.php";
const baseURL = "/api.php";

const PENDING = 0;

const IN_QUEUE = 1;
const IN_PROGRESS = 2;
const FINISHED = 3;

function addJob(url, type, callback)
{
    let formdata = new FormData();
    formdata.append("url", url);
    formdata.append("type", type);
    axios.post(
        baseURL + "?action=addJob",
        formdata
    ).then(res => callback(res.data));
}

function getJobs(callback){
    axios.get(baseURL + "?action=getQueue")
    .then(res => callback(res.data));
}

function getStatus(status_code){
    let status = {
        0 : <span className="status-pending">Pending</span>,
        1 : <span className="status-in-queue">In queue</span>,
        2 : <span className="status-in-progress">In progress</span>,
        3 : <span className="status-finished">Finished</span>
    }
    return status[status_code];
}

function removeJob(id,callback=(json) => {}){
    let formdata = new FormData();
    formdata.append("id", id);
    axios.post(
        baseURL + "?action=removeJob",
        formdata
    ).then(res => callback(res.data));
}

function checkLogin(callback){
 axios.get(baseURL + "?action=checkLogin").then(
        res => callback(res.data)
    );
}

function login(password,remember, callback){
    let formdata = new FormData();
    formdata.append("password", password);
    formdata.append("remember", remember ? "true" : "false");
    axios.post(
        baseURL + "?action=login",
        formdata
    ).then(
        res => {return callback(res.data)}
    );
}

function createSession(uid, callback){
    axios.get(baseURL + "?action=createSession&uid=" + uid).then(
    (res) => callback(res.data.success));
}

function storeMailAddress(mail){
    let formdata = new FormData();
    formdata.append("mail", mail);
    axios.post(baseURL + "?action=storeMailAddress", formdata);
}

function sendJobList(mail,callback){
    let formdata = new FormData();
    formdata.append("mail", mail);
    axios.
    post(baseURL + "?action=sendJobList", formdata)
        .then(res => callback(res.data));
}

export {addJob,getStatus,getJobs,removeJob,checkLogin,login,createSession,storeMailAddress,sendJobList,PENDING,IN_QUEUE,IN_PROGRESS,FINISHED}